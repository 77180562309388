.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  margin-right: 3em;
}
.toggle-button:focus {
  outline: none;
}
.toggle-button__line {
  width: 35px;
  height: 3px;
  background: #111;
  margin-left: 0.5em;
}
