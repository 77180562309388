.side-drawer {
  background: #a3c6c4;
  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
  font-family: "Courier New", Courier, monospace;
  height: 100%;
  left: 0;
  max-width: 400px;
  overflow-y: auto;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition: transform 0.5s ease-out;
  width: 70%;
  z-index: 200;
  scrollbar-width: none; /* For Firefox */
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.side-drawer ul {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  list-style: none;
  padding-bottom: 25px;
}

.side-drawer li {
  margin: 0.8rem 0;
}

.side-drawer a {
  border-radius: 5px;
  color: black;
  font-size: 1.5rem;
  letter-spacing: 5px;
  padding: 0.2em 0.5em;
  text-decoration: none;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #cdebeb;
}

@media (min-width: 960px) {
  .side-drawer {
    display: none;
  }
}
