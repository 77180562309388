header {
  font-family: "Courier New", Courier, monospace;
  font-size: 18px;
}

.navigation__bar {
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #c2f0c2;
  height: 56px;
  z-index: 10;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.main__Navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem 0 1rem;
}

.navigation__logo img {
  margin-left: 4rem;
}

.navigation__logo a {
  color: black;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.navigation__logo a:hover {
  text-decoration: none;
  color: none;
}

.navigation__logo h5 {
  text-transform: uppercase;
  margin: 0 0 0 1em;
  padding: 0;
}

.spacer {
  flex: 1;
}

.navigation-items ul {
  list-style: none;
  margin: 0 4rem 0 0;
  padding: 0;
  display: flex;
}

.navigation-items li {
  padding: 0 1rem;
}

.navigation-items a {
  color: black;
  text-decoration: none;
  text-transform: uppercase;
}

.navigation-items a:hover,
.navigation-items a:active {
  color: gray;
  text-decoration: none;
}

@media (max-width: 960px) {
  .navigation-items {
    display: none;
  }
  .navigation__logo img {
    margin: 0;
  }
  .navigation__logo a h5 {
    font-size: 15px;
    margin-left: 1rem;
  }
}

@media (min-width: 960px) {
  .navigation__toggle-button {
    display: none;
  }
}
