:root {
  --main-bg-color: #a3c6c4;
  --btn-bg-color: #c2f0c2;
  --content-bg-color: #cdebeb;
  --main-font: "Courier New", Courier, monospace;
}

html {
  background-color: var(--main-bg-color);
  letter-spacing: 2px;
  height: 100%;
  overflow-x: hidden;
}

body,
main,
.container,
.footer,
.img-thumbnail {
  background-color: var(--main-bg-color);
}

body {
  font: normal 300 18px/1.5 var(--main-font);
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  overflow-x: hidden;
  outline: none;
}

.btn {
  color: #000;
  border: 2px solid #000;
  background-color: var(--btn-bg-color);
}

.btn:hover,
.btn:active {
  background-color: var(--main-bg-color);
  color: #000;
}

ul {
  list-style-type: none;
  padding: 0;
}

.main-navigation-list li:last-child {
  font-weight: bold;
}

.container {
  border-radius: 5px;
  color: black;
}

.content {
  background-color: var(--content-bg-color);
}

#app div {
  height: 100%;
}

.breakfast {
  padding: 1em;
  font-size: 1.25rem;
  width: 100%;
}

.breakfast h6 {
  line-height: 25px;
  font-size: 16px;
}

.veganBefore {
  display: none;
}

.adress {
  line-height: 30px;
}

.drinks {
  width: 210px;
  padding: 1em;
}

.drinks p,
.index-photo {
  margin: 0;
}

.extras {
  font-size: 1rem;
}

.history {
  width: 70%;
}

.rentSections {
  width: 75%;
}

.image-gallery {
  margin-bottom: 2em;
}

.plock-tallrik {
  display: "flex";
  flex-direction: column-reverse;
  /* align-items: "start"; */
}

.skeleton {
  width: 100%;
  background: linear-gradient(-90deg, #d3cfcf 0%, #d8d5d5 50%, #d3cfcf 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}

.copyright {
  font-size: 10px;
}

.footer {
  color: black;
  font-family: var(--main-font);
}

.footer-parent {
  width: 100%;
}

.footer-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.footer p {
  font-size: 15px;
  text-transform: uppercase;
}

.infobox-container {
  display: flex;
  justify-content: space-around;
}

.rental-gallery {
  width: 80%;
}

.contact-skeleton {
  width: 90%;
  height: 450px;
  background: #eee;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(90deg, #f0f0f0 25%, #f8f8f8 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  to {
    background-position: 200% 0;
  }
}

.plock-tallrik-image-container {
  height: 350px;
}

@media (max-width: 630px) {
  .veganBefore {
    display: block;
  }

  .rental-gallery {
    width: 100%;
  }

  .plock-tallrik-image-container {
    height: 290px;
  }

  .rentSections {
    width: 100%;
  }

  .veganAfter {
    display: none;
  }

  .two {
    order: 3;
    margin: 1em 0;
  }

  .footer {
    padding-top: 0;
  }

  .infobox-container {
    flex-direction: column;
    align-items: center;
  }

  .footer,
  .footer-parent {
    display: flex;
    justify-content: center;
  }

  .footer-parent {
    align-items: center;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .footer-contact {
    order: 1;
  }

  .footer-social,
  .footer-hours,
  .footer-paragraph {
    padding-top: 15px;
  }

  .footer-social {
    order: 3;
  }

  .footer-hours {
    order: 2;
  }

  .index-photo {
    margin-bottom: 1.5em;
  }

  .history {
    width: 80%;
  }

  .content h5 {
    font-size: 18px;
  }

  .menu-list h5 {
    font-size: 16px;
  }

  .menu-item,
  .veganMenuItem {
    display: flex;
    flex-direction: column;
  }

  h6 em {
    font-size: 14px;
  }
}

.grid-item {
  background: #ccc;
}

.grid-item > img {
  margin-bottom: 10px;
  display: block;
  width: 300px;
}
